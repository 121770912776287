// CONTENEDOR QUE VERIFICA LA AUTENTIFICACIÓN DE LOS USUARIOS Y PASA EL RESULTADO DE AUTH0 A SUS HIJOS POR MEDIO DE PROPS AGREGADAS:

import React, {useEffect} from "react";
import {useAuth0} from "@auth0/auth0-react";

import {userVar} from "../../gatsby-plugin-apollo/cache";

import {AuthProvider} from "./AuthProvider";

const AuthContainer = ({children}) => {
    const {getAccessTokenSilently, isAuthenticated, user, isLoading, loginWithRedirect, logout} =
        useAuth0();

    useEffect(() => {
        const acessToken = async () => {
            if (isAuthenticated) {
                const token = await getAccessTokenSilently();

                userVar({
                    email: user.email,
                    name: user.given_name,
                    surname: user.family_name,
                    picture: user.picture,
                    id: user["https://weproject.management/app_metadata"]?.id || null,
                    token,
                });
            }
        };

        acessToken();
    }, [user, isAuthenticated, getAccessTokenSilently, isLoading]);

    return (
        <AuthProvider value={{isAuthenticated, isLoading, loginWithRedirect, logout}}>
            {children}
        </AuthProvider>
    );
};

export default AuthContainer;
